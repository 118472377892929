import { createTheme } from '@material-ui/core'
import lightBlue from '@material-ui/core/colors/lightBlue';
import seamlessBG_Dark from "../../assets/images/seamlessTools303030GreyPlain.svg";

const backgroundImage = seamlessBG_Dark; 
const backgroundColor = '#383838';  

let darkTheme = createTheme({
    palette:{
      type: 'dark',
      primary: {
        light: lightBlue[500],
        main: lightBlue[800],
        dark: lightBlue[300],
      },
      secondary: {
        light: '#e1e1e1',
        main: '#f1f1f1',
        dark: '#e1e1e1',
      },
    },
    overrides: {
      MuiTab: {
        root: {
          "&.MuiTab-root": {
            color: '#000',
            opacity:1
          },
          "&.Mui-selected": {
          }
        }
      },
      MuiCssBaseline: {
        '@global':{
          body:{
            backgroundColor: `${backgroundColor}`,
            backgroundImage:`URL(${backgroundImage})`,
            backgroundRepeat:'repeat',
            backgroundSize:'100px 100px',
          }
        }
      },
      
      // For full box service cards
      MuiCardContent: {
        root: {
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
         },
        },
      },
      // thinning out cardheader in services
      MuiCardHeader: {
        root: {
          padding: 4,
        }
      },
      MuiFormControlLabel: {
        label: {
          fontSize: 16,
          marginRight: '0px',
        },
      },
      MuiButton: {
        containedPrimary: {
          color: 'white',
        },
      },
    },
    typography: {
      h1: {
        fontFamily: "Pacifico"
      },
      h2: {
        fontFamily: "Pacifico"
      },
      h3: {
        fontFamily: "Pacifico"
      },
      h4: {
        fontFamily: "Pacifico"
      },
      h5: {
        fontFamily: 'Roboto',
        fontWeight: 700
      },
      h6: {
        fontFamily: 'Roboto',
        fontWeight: 400
      },
      button: {
        fontFamily: 'Roboto',
        fontWeight: 400
      }
    }
  })

export default darkTheme