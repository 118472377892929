import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    spinnerContent: {
        justifyContent: 'center',
        textAlign: 'center',
        // padding: theme.spacing(24, 0, 24),
        position: 'absolute',
        height: '100px',
        width: '100px',
        top: '50%',
        left: '50%',
        marginLeft: '-50px',
        marginTop: '-50px',
    },
}));

export default function Spinner() {
  const classes = useStyles();

  return (
    <div className={classes.spinnerContent}>
      <CircularProgress />
    </div>
  );
}