import React, { lazy, Suspense, useState } from "react";

import {
  createMuiTheme,
  MuiThemeProvider,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core";
import seamlessBG from "./assets/images/seamlessTools10GreyPlain.svg";
import seamlessBG_Dark from "./assets/images/seamlessTools303030GreyPlain.svg";

// colors
import lightBlue from "@material-ui/core/colors/lightBlue";

// themes
import darkTheme from "./components/Themes/DarkTheme";
import lightTheme from "./components/Themes/LightTheme";
import texasTheme from "./components/Themes/TexasTheme";

import { Route, Switch } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Appbar from "./components/Navbar/Appbar";
import Footer from "./components/Footer/Footer";
import CircularLoader from "./components/Loader/Spinner";

const Home = lazy(() => import("./components/pages/Home"));
const Paint = lazy(() => import("./components/pages/Paint"));
const Build = lazy(() => import("./components/pages/Build"));
const Woodwork = lazy(() => import("./components/pages/Woodwork"));
const Handy = lazy(() => import("./components/pages/Handy"));

const Album = lazy(() => import("./components/Album/Album"));
const Gallery = lazy(() => import("./components/pages/Gallery"));
const About = lazy(() => import("./components/pages/About"));
const Faq = lazy(() => import("./components/pages/FAQ"));

function App() {
  // dark theme switch state
  // const [darkTheme, setDarkTheme] = React.useState(false)

  // dark theme switch state
  const [darkState, setDarkState] = useState(false);
  const [secretState, setSecretState] = useState(0);

  const toggleSecretTheme = () => {
    setSecretState(secretState + 1);
  };
  const toggleDarkTheme = () => {
    setDarkState(!darkState);
  };

  // let currentTheme = (secretState == 12) ? texasTheme : darkState ? darkTheme : lightTheme

  const backgroundImage = darkState ? seamlessBG_Dark : seamlessBG;
  const backgroundColor = darkState ? "#383838" : "#fff";
  const svgColor =
    secretState == 12 ? "#1f1f1f" : darkState ? "#fff" : "#1f1f1f";

  // overide theme

  let currentTheme =
    secretState == 12 ? texasTheme : darkState ? darkTheme : lightTheme;
  currentTheme = responsiveFontSizes(currentTheme);

  return (
    <ThemeProvider theme={currentTheme}>
      <div className="App">
        <Appbar
          style={{
            background: "linear-gradient(to right bottom, #430089, #82ffa1)",
          }}
          onToggleSecret={(e) => toggleSecretTheme()}
          onToggleDark={(e) => toggleDarkTheme()}
          // changeDark={(dark) =>
          //   {setDarkState(!dark)
          //     console.log(darkState)
          //   }}
          //   onToggleDark={e => toggleDarkTheme()}
        />

        <div className="content" style={{ minHeight: "95vh" }}>
          <AnimatePresence>
            <Switch>
              <Suspense fallback={<CircularLoader />}>
                <Route exact path="/">
                  {" "}
                  <Home />{" "}
                </Route>
                <Route path="/painting">
                  {" "}
                  <Paint />{" "}
                </Route>
                <Route path="/woodworking">
                  {" "}
                  <Woodwork />{" "}
                </Route>
                <Route path="/building">
                  {" "}
                  <Build />{" "}
                </Route>
                <Route path="/handyman">
                  {" "}
                  <Handy />{" "}
                </Route>
                <Route path="/album" component={Album} />
                <Route path="/gallery" component={Gallery} />
                <Route path="/about" component={About} />
                <Route path="/faq" component={Faq} />
              </Suspense>
            </Switch>
          </AnimatePresence>
        </div>
        <Footer svgColor={svgColor} />
      </div>
    </ThemeProvider>
  );
}

export default App;
