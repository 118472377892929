import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';

import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from 'react-router-dom';

import TabSection from "./Tabs";
import { CssBaseline } from '@material-ui/core';

// icons
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { GiTexas } from "react-icons/gi";

// logos
import LightLogo from '../../assets/images/saw-logo-inverse-corrected.svg'
import DarkLogo from '../../assets/images/saw-logo-corrected.svg'

const useStyles = makeStyles((theme) => ({
    logo: {
        flex:1,
        marginTop: 4,
    },
    icon: {
      // marginRight: theme.spacing(2),
      // color: dark ?  '#000' : '#fff',
      [theme.breakpoints.up('md')]: {
        display: 'none'
    }
    },
    darkIcon: {
      // marginRight: theme.spacing(2),
      color: '#000',
      [theme.breakpoints.up('md')]: {
        display: 'none'
    }
    },
    menuLink: {
      textDecoration: 'none'
    },
    appBar:{
      // height: '80px',
    }
}));

const Appbar = (
  props,
  //  onToggleDark
   ) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);




  // dark theme switch state
  const [dark, setDark] = React.useState({
    on: false,
    checked: false,
  });
  const [secret, setSecret] = React.useState(0);



  const darkFunction = () => {
    setDark(!dark);
    props.onToggleDark();
  }

  const secretFunction = () => {
    setSecret(secret + 1);
    props.onToggleSecret();
  }

  // const secretResetFunction = () => {
  //   secret > 7 ? setSecret(0) : null 
  // }
// Could rewrite with usereducer
  function darkSecret(){
    darkFunction(); 
    secretFunction(); 
    // secretResetFunction();
  }





  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

// // dark theme switch state
//   const [dark, setDark] = React.useState({
//     on: false,
//     checked: false,
//   });

  const handleChange = (event) => {
    setDark({ ...dark, [event.target.name]: event.target.checked });
  };

//   const darkFunction = () => {
//     setDark(!dark);
//     props.onToggleDark();
// }

// active tab state on logo click
const [activeTab, setActiveTab] = React.useState({
  activeTab: 0,
});

  const logoClickFunction = () => {
    setActiveTab(0)
    console.log(activeTab)
  }

    return (
        <>
          <CssBaseline />
            <AppBar position="sticky"
              className={classes.appBar}>
                <Toolbar>
                <div className={classes.logo}>
                  <Link to="/" >
                  <img 
                    // width="9.5%"
                    height="56vh" 
                    width="56vw"
                    src={dark ?  DarkLogo : LightLogo}
                    component={Link} to="/"
                    onClick={logoClickFunction}
                    alt="DH company logo"
                  />
                  </Link>
                </div>

                <TabSection active={activeTab}/>

                <IconButton 
                borderRadius="50%" 
                onChange={handleChange}
                // onClick={
                //   darkFunction
                // }
                onClick={
                  darkSecret
                }
                // color="inherit"
                name="on"
                aria-label="dark mode toggle button"
                style={dark ? { color: 'rgba(0,0,0,.87)' } : { color: '#fff' }}
                >
                {secret == 11 ? <GiTexas/> : dark ?  <NightsStayIcon/> : <WbSunnyIcon/> }
                {/* {dark ?  <NightsStayIcon/> : <WbSunnyIcon/> } */}
              </IconButton>
                <IconButton 
                edge="start"
                // color="inherit"
                className={classes.icon}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                aria-label="mobile menu button"
                // style={dark ?  classes.darkIcon : classes.icon}
                style={dark ? { color: 'rgba(0,0,0,.87)' } : { color: '#fff' }}
                >
                <MenuIcon />
                </IconButton>
                    <Popper 
                    open={open} 
                    anchorEl={anchorRef.current} 
                    role={undefined} 
                    transition 
                    disablePortal>
                        {({ TransitionProps, placement }) => (
                        <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                    <MenuList aria-label="menu button" autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem component={Link} to="/" onClick={handleClose} aria-label="Services button">
                          Services
                        </MenuItem>
                        <MenuItem component={Link} to="/about" onClick={handleClose} aria-label="About button">
                          About
                        </MenuItem>
                        <MenuItem component={Link} to="/gallery" onClick={handleClose} aria-label="Gallery button">
                          Gallery
                        </MenuItem>
                    </MenuList>
                    </ClickAwayListener>
                </Paper>
                </Grow>
            )}
            </Popper>
            </Toolbar>
            </AppBar>   
        </>
    )
}

export default Appbar
