import React, { useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    tabsSection: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
          display: 'none'
      },
    },
    fullHeight: {
      ...theme.mixins.toolbar,
    },
}));


const TabSection = ( props ) => {
    console.log(this)
    console.log(props.active)
    console.log(props.active.activeTab)
    const classes = useStyles();
    // for tabs
    const [value, setValue] = React.useState(props.active.activeTab);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const updateChange = (value) => {
    //     setValue(value);
    // };



    useEffect(() => {
        let url = window.location.href.split("/");
        let target = url[url.length - 1].toLowerCase();
        let element = document.getElementById(target);
        element && element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, []);

    return (
        <Tabs 
            className={classes.tabsSection}
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            >
            <Tab component={NavLink} smooth to="/" label={`Services`} 
            classes={{ root: classes.fullHeight }}
            />
            <Tab component={NavLink} smooth to="/about" classes={{ root: classes.fullHeight }} label="About" />
            <Tab component={NavLink} smooth to="/gallery" classes={{ root: classes.fullHeight }} label="Gallery" /> 
        </Tabs>
    )
}

export default TabSection
